/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';
@import 'primeicons/primeicons.css';

@font-face {
  font-family: 'TT Chocolates Trl Regular';
  src: url('./assets/fonts/TT Chocolates Trial Regular.otf');
}

@font-face {
  font-family: 'TT Chocolates Trl Bold';
  src: url('./assets/fonts/TT Chocolates Trial Bold.otf');
}

* {
  box-sizing: border-box;
  font-family: 'TT Chocolates Trl Regular', sans-serif;
}

html,
body {
  min-width: 370px;
  margin: 0;
  padding: 0;
  max-width: 100%;
  height: 100%;
  background: #f3f5f6;
}

ul {
  margin-bottom: 0;
}

.list-unstyled {
  list-style: none;
}

.flex-gap-1 {
  gap: 16px;
}

.border-1 {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.bg-transparent {
  background-color: transparent;
  outline-color: transparent;
  border-color: transparent;
}

.bg-white {
  background-color: var(--white);
  outline-color: var(--white);
  border-color: var(--white);
  color: var(--black);
}

.bg-dark-white {
  background-color: var(--dark-white);
  outline-color: var(--dark-white);
  border-color: var(--dark-white);
  color: var(--black);
}

.bg-red {
  background-color: var(--red);
  outline-color: var(--red);
  border-color: var(--red);
  color: var(--white);
}

.bg-black {
  background-color: var(--black);
  outline-color: var(--black);
  border-color: var(--black);
  color: var(--white);
}

.bg-outline-black {
  background-color: transparent;
  outline-color: var(--black);
  border-color: var(--black);
  color: var(--black);
}

.bg-orange {
  background-color: var(--orange);
  outline-color: var(--orange);
  border-color: var(--orange);
  color: var(--white);
}

.bg-yellow {
  background-color: var(--yellow);
  outline-color: var(--yellow);
  border-color: var(--yellow);
  color: var(--black);
}

.bg-blue {
  background-color: var(--blue);
  outline-color: var(--blue);
  border-color: var(--blue);
  color: var(--white);
}

.bg-dark-blue {
  background-color: var(--dark-blue);
  outline-color: var(--dark-blue);
  border-color: var(--dark-blue);
  color: var(--white);
}

.bg-brown {
  background-color: var(--brown);
  outline-color: var(--brown);
  border-color: var(--brown);
  color: var(--white);
}

.bg-outline-brown {
  background-color: transparent;
  outline-color: var(--brown);
  border-color: var(--brown);
  color: var(--brown);
}

.bg-purple {
  background-color: var(--purple);
  outline-color: var(--purple);
  border-color: var(--purple);
  color: var(--white);
}

.bg-green {
  background-color: var(--green);
  outline-color: var(--green);
  border-color: var(--green);
  color: var(--white);
}

.bg-light-gray {
  background-color: var(--light-gray);
  outline-color: var(--light-gray);
  border-color: var(--light-gray);
  color: var(--black);
}

b,
.font-family-bold {
  font-family: 'TT Chocolates Trl Bold', sans-serif;
}

.text-danger .p-menuitem-text {
  color: var(--red) !important;
}

.text-success {
  color: var(--green) !important;
}

.text-purple {
  color: var(--purple);
}

.text-black {
  color: var(--black);
}

.fullscreen-xs {
  @media (max-width: 768px) {
    width: 100vw;
    height: 100vh;
  }
}

.fullscreen {
  width: 100vw;
  height: 100vh;
}

.fullwidth {
  width: 100% !important;
}

.spinner-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(70, 79, 91, 0.5);
  z-index: 4;
}

:root {
  --orange: #c89835;
  --yellow: #ffd600;
  --white-red: #feede9;
  --danger: #feede9;
  --white: #fff;
  --dark-white: #ccc;
  --darker-white: #e2e2e2;
  --green: #35d073;
  --blue: #75bcd1;
  --dark-blue: #7b61ff;
  --purple: #8738b0;
  --red: #f54721;
  --black: #1a1a1b;
  --gray: #3e3c3c;
  --dark-gray: #606060;
  --gray-blue: #181d31;
  --brown: #6d584b;
  --light-gray: #acacac;
  --bg-color: #f3f5f6;
  --font-color: #222222;
}

.color-light-gray {
  color: var(--light-gray);

  &:hover {
    color: var(--light-gray);
  }
}

.color-black {
  color: var(--black);

  &:hover {
    color: var(--black);
  }
}

.color-gray {
  color: var(--gray);

  &:hover {
    color: var(--gray);
  }
}

.color-gray-blue {
  color: var(--gray-blue);

  &:hover {
    color: var(--gray-blue);
  }
}

.color-dark-white {
  color: var(--dark-white);

  &:hover {
    color: var(--dark-white);
  }
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-md-16 {
  @media screen and (min-width: 585px) {
    font-size: 16px !important;
  }
}

.font-20 {
  font-size: 20px !important;
}

.font-weight-500 {
  font-weight: 600 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.card-title {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

p-skeleton {
  width: 100%;
}

.ebf-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;

  &.underlined-table {
    th,
    td {
      border-bottom: 1px solid var(--dark-white);
    }

    tr:last-child td {
      border-bottom: none;
    }
  }

  th,
  td {
    padding: 16px 24px;
  }

  th {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #606060;
  }

  tbody tr {
    background: var(--white);
    border-spacing: 8px;
  }
}

.table-wrapper {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.p-multiselect {
  width: 100%;
  height: 40px;
}

.p-multiselect .p-multiselect-label {
  padding: 0.5rem 0.75rem;
}

.p-multiselect-close {
  display: none;
}

.p-dropdown-label {
  padding: 12px;
  font-size: 14px;
  line-height: 16px;
}

.p-dropdown-items {
  margin-bottom: 0 !important;
  padding: 0 !important;
}

label,
.col-form-label {
  position: relative;
  font-weight: 600;
  margin-bottom: 4px;
  font-size: 14px;

  &[required]:not([required='false'])::after,
  &[required='true']::after {
    position: absolute;
    top: 5px;
    right: -10px;
    content: '*';
    display: inline-block;
    color: var(--red);
    font-size: 17px;
    line-height: 12px;
  }
}

.is-invalid .p-dropdown,
.is-invalid .p-autocomplete-dd-input,
.is-invalid .p-autocomplete-dropdown {
  border-color: var(--red) !important;
}

.control-wrapper {
  margin-bottom: 24px;
}

.modal {
  z-index: 11000;
}

.chip {
  display: inline-block;
  padding: 4px 6px;
  margin-right: 12px;
  margin-bottom: 4px;
  border-radius: 20px;
  border: 1px solid var(--dark-white);
  font-size: 14px;

  img {
    cursor: pointer;
  }
}

.c-pointer {
  cursor: pointer;
}

p-dropdown.ng-invalid.ng-dirty .p-dropdown {
  border-color: var(--red);
}

.p-multiselect-panel .p-multiselect-item {
  display: flex;
  align-items: flex-start;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus {
  background: var(--white);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--black);
  background: var(--white);
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--brown);
}

body .p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--brown);
  background: var(--brown);
}

body .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus,
body .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus,
.btn:focus,
.btn.focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 74, 44, 0.25);
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: var(--brown);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 12px;
}

.p-accordion-header {
  background: var(--white);

  .pi {
    position: absolute;
    right: 15px;
    padding: 12px;
    background: var(--bg-color);
    color: var(--orange);
    border-radius: 50%;
  }

  a {
    font-size: 20px;
    color: var(--black);
    text-decoration: none;
  }
}

.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 8px;
}

.p-accordion .p-accordion-content {
  background: transparent;
  padding: 24px;
}

.sorting {
  cursor: pointer;
}

.sorting-asc,
.sorting-desc {
  cursor: pointer;
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    right: -10px;
    top: 15px;
    width: 16px;
    height: 16px;
    background-image: url('./assets/images/icons/sort.svg');
  }
}

.sorting-asc::after {
  transform: rotate(180deg);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover,
.p-inputtext:enabled:hover,
.p-multiselect:not(.p-disabled):hover,
.p-dropdown:not(.p-disabled):hover {
  border-color: var(--light-gray);
}

.p-dropdown:not(.p-disabled).p-focus,
.p-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 74, 44, 0.25);
}

.p-multiselect:not(.p-disabled).p-focus,
.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 74, 44, 0.25);
  border-color: var(--light-gray);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #495057;
  background-color: #80808040;
}

.p-dropdown-panel.p-component {
  width: 100%;
}

.p-dropdown-panel .p-dropdown-filter {
  font-size: 14px;
  height: 40px;
}

.p-multiselect-header {
  display: none;
}

.p-toast-detail::first-letter {
  text-transform: uppercase;
}

p-dialog .p-dialog div.p-dialog-header {
  padding: 20px 20px 12px;
}

p-dialog .p-dialog div.p-dialog-content {
  padding: 0 20px 20px;
}

.p-dialog-mask button.p-dialog-header-close {
  position: absolute;
  top: 15px;
  right: 15px;
  line-height: 22px;
  font-size: 22px;
  border: none;
  background-color: transparent;
  color: var(--light-gray);
  padding: 8px;

  &:hover {
    background-color: var(--bg-color);
  }
}

.p-multiselect.p-disabled,
.p-dropdown.p-disabled {
  background-color: var(--darker-white);
}

.form-control:focus {
  color: #495057;
  background-color: var(--white);
  border-color: var(--light-gray);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(99, 74, 44, 0.25);
}

.is-invalid ngx-dropzone {
  border-color: var(--red) !important;
}

ngx-dropzone {
  background-color: transparent !important;
  border: 1px dashed var(--dark-white) !important;
}

.mobile-button {
  display: block;

  @media screen and (min-width: 585px) {
    bottom: 70px;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.icon-reversed {
  transform: rotate(180deg);
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-transform-initial {
  text-transform: initial;
}

.tooltip-lg {
  min-width: 260px;
}

.pac-container::after {
  background-image: none !important;
  height: 0;
}

ebf-table {
  .pagination {
    display: block;

    @media screen and (min-width: 768px) {
      display: flex;
    }

    &-inner {
      display: flex;
    }

    .page-link {
      color: var(--gray-blue);

      &:focus {
        box-shadow: none;
      }
    }

    .page-item.active .page-link {
      border: 1px solid var(--orange);
      background-color: var(--orange);
    }
  }

  .custom-select:focus {
    box-shadow: none;
    border: 1px solid #ced4da;
  }
}

.bg-success .p-progressbar .p-progressbar-value {
  background-color: var(--success) !important;
}

.p-inputswitch .p-inputswitch-slider::before {
  transition-duration: 0s !important;
}

p-card.fullscreen-xs {
  .p-card.p-component {
    @media (max-width: 768px) {
      height: 100vh;
    }
  }
}

p-autocomplete {
  button.p-autocomplete-dropdown {
    background: white;
    color: #6e6d6d;
    border: 1px solid #ced4da;
    display: flex;
    border-left: none;
  }

  .p-autocomplete-dd-input {
    line-height: 16px;
    font-size: 14px;
  }
}
